import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import APIS from "../../../../common/hooks/UseApiCalls";
import toast from "react-hot-toast";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import "./AddChildForm.css";
import { useNavigate } from "react-router";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { ModalService } from "../../../../components/Modal";
import { PhoneTextInput } from "../../../../components/PhoneTextInput/PhoneTextInput";
import { useDebouncedCallback } from "use-debounce";
import { getFirstErrorKey } from "../../../../helpers/helperFunction";
import { CommonDataContext } from "../../../../common/contexts/CommonDataContext";
import Loader from "../../../../components/UserComponents/Loader";

const phoneUtil = PhoneNumberUtil.getInstance();

const CareGiverForm = ({
  familyData,
  setIsCareGiverInfo,
  caregiverInfo,
  setCaregiverInfo,
  householdAndAgencyInfo,
}) => {
  const { locationList } = useContext(CommonDataContext);

  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();

  const debouncedHandleCheckEmailExistence = useDebouncedCallback(
    // function
    async (email) => {
      const res = await APIS.CheckUserEmailExists(email.toLowerCase());
      if (res?.data?.message === "EMAIL_EXIST") {
        return false;
      } else {
        return true;
      }
    },
    800
  );
  const validationSchema = Yup.object().shape({
    parents: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        lastName: Yup.string().max(255).required("Last name is required"),
        email: Yup.string()
          .email("Please enter a valid email address")
          .max(255)
          .required("Please enter a valid email address")
          .test(
            "email-name-unique",
            "This email address is already in use",
            async (email, context) => {
              try {
                if (
                  email.length &&
                  caregiverInfo?.parents?.[
                    context?.options?.index
                  ]?.email?.toLowerCase() !== email?.toLowerCase()
                ) {
                  const debounceResponse =
                    debouncedHandleCheckEmailExistence(email);
                  return debounceResponse;
                }
                return true;
              } catch (error) {
                return false; // Handle parsing errors
              }
            }
          ),
        phoneNumber: Yup.string()
          .required("Phone number is required")
          .test(
            "phone-format-validation",
            "Please enter a valid phone number",
            (value) => {
              try {
                const phoneNumber = phoneUtil.parseAndKeepRawInput(value);
                return phoneUtil.isValidNumber(phoneNumber);
              } catch (error) {
                return false; // Handle parsing errors
              }
            }
          ),
      })
    ),
  });

  const initialValues = {
    parents: caregiverInfo
      ? caregiverInfo.parents
      : [
          {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            occupation: "",
          },
        ],
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: (values) => handleSubmitHandler(values),
    validateOnChange: true,
  });

  const {
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = formik;

  // useEffect(() => {
  //   if (!formik.isValid && formik.submitCount !== 0 && formik.isSubmitting) {
  //     const firstErrorKey = getFirstErrorKey(formik.errors);
  //     if (global.window.document.getElementsByName(firstErrorKey).length) {
  //       global.window.document.getElementsByName(firstErrorKey)[0].focus();
  //     }
  //   }
  // }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting]);

  useEffect(() => {
    const el = document.querySelector(".Mui-error, [data-error]");
    (el?.parentElement ?? el)?.scrollIntoView();
    (el?.parentElement ?? el)?.focus();
  }, [isSubmitting]);

  useEffect(() => {
    if (caregiverInfo?.parents) {
      setFieldValue("parents", caregiverInfo?.parents);
    } else {
      setFieldValue("parents", [
        {
          firstName: householdAndAgencyInfo.firstName,
          lastName: householdAndAgencyInfo.lastName,
          email: "",
          phoneNumber: "",
          occupation: "",
        },
      ]);
    }
  }, [caregiverInfo, householdAndAgencyInfo]);

  const handleSubmitHandler = (data) => {
    caregiverInfo?.parents[0]?.id
      ? updateCaregiverdHandler(data)
      : createCaregiverdHandler(data);
  };

  const createCaregiverdHandler = async (data) => {
    setIsLoading(true);
    let params = {
      MPAccountId: localStorage.getItem("orgId"),
      MPCountryId: localStorage.getItem("userRegion"),
      caseManagerId: householdAndAgencyInfo.casemanagerId.id,
      MPLanguageId: "1",
      FSUserRoleId: "8",
      ...data.parents[0],
    };
    params.email = params.email.toLowerCase();
    if (data.parents.length === 2) {
      params.secondaryParent = { ...data.parents[1] };
      params.secondaryParent.email = params.secondaryParent.email.toLowerCase();
    } else params.secondaryParent = null;
    if (
      params.secondaryParent &&
      params.email === params.secondaryParent.email
    ) {
      setIsLoading(false);
      toast.error("Primary and Secondary Parent Email cannot be same", {
        id: "email-unique",
      });
      setSubmitting(false);
    } else {
      try {
        const res = await APIS.createFsParents(params);
        if (res?.status === 200) {
          toast.success(res.data.message);
          try {
            setTimeout(async () => {
              let res2 = await APIS.linkFsParents({
                primaryParent: res.data?.data?.parentId,
                secondaryParent: res.data?.data?.secondaryParentId,
                family: householdAndAgencyInfo.id,
              });
              if (res2?.response?.status === 500)
                setTimeout(async () => {
                  await APIS.linkFsParents({
                    primaryParent: res.data?.data?.parentId,
                    secondaryParent: res.data?.data?.secondaryParentId,
                    family: householdAndAgencyInfo.id,
                  });
                }, 100);
            }, 100);
          } catch (error) {}
          setTimeout(() => {
            navigate("/fostershare/families");
            setIsLoading(false);
          }, 5000);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const updateCaregiverdHandler = async (data) => {
    setIsLoading(true);

    let params = {
      MPAccountId: localStorage.getItem("orgId"),
      MPCountryId: localStorage.getItem("userRegion"),
      caseManagerId: householdAndAgencyInfo.casemanagerId.id,
      MPLanguageId: "1",
      FSUserRoleId: "8",
      ...data.parents[0],
    };
    params.email = params.email.toLowerCase();
    if (data.parents.length === 2) {
      params.secondaryParent = { ...data.parents[1] };
      params.secondaryParent.email = params.secondaryParent.email.toLowerCase();
    } else params.secondaryParent = null;
    if (
      params.secondaryParent &&
      params.email === params.secondaryParent.email
    ) {
      setIsLoading(false);
      toast.error("Primary and Secondary Parent Email cannot be same", {
        id: "email-unique",
      });
      setSubmitting(false);
    } else {
      try {
        const res = await APIS.updateFsParents(params);
        if (res.status === 200) {
          toast.success(res.data.message);
          if (params.secondaryParent && !params.secondaryParent?.id) {
            try {
              setTimeout(async () => {
                const res2 = await APIS.linkFsParents({
                  primaryParent: res.data?.data?.parentId,
                  secondaryParent: res.data?.data?.secondaryParentId,
                  family: householdAndAgencyInfo.id,
                });
                if (res2?.response?.status === 500)
                  setTimeout(async () => {
                    await APIS.linkFsParents({
                      primaryParent: res.data?.data?.parentId,
                      secondaryParent: res.data?.data?.secondaryParentId,
                      family: householdAndAgencyInfo.id,
                    });
                  }, 100);
              }, 100);
            } catch (error) {
              console.log(error);
            }
          }
          navigate(`/fostershare/families/${householdAndAgencyInfo.id}`);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const cancelClickHandler = () => {
    ModalService.open(
      () => (
        <Box mb={2}>
          If you leave now, you can return to this family to update caregiver
          details.
        </Box>
      ),
      {
        modalTitle: "Missing caregiver information",
        width: "30%",
        modalDescription:
          "Caregiver(s) have not been added to this household. At least one caregiver must be created to assign children to this family.",
        actionButtonText: "Leave page",
        cancelButtonText: "Return to form",
        onClick: () => navigate("/fostershare/families"),
      }
    );
  };

  return (
    <>
      <Loader loading={isLoading} />

      <FormikProvider value={formik}>
        <FieldArray name="parents">
          {({ insert, remove, push }) => (
            <>
              {values.parents.map((obj, i) => (
                <>
                  <Grid item xs={12}>
                    <Typography
                      id="parent-info"
                      color="textPrimary"
                      variant="subtitle2"
                      fontSize={"1.1rem"}
                      fontWeight={600}
                    >
                      {`${i === 0 ? "Primary" : "Secondary"} caregiver`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id={`parent-${i}-first-name`}
                      error={Boolean(
                        touched?.parents?.[i]?.firstName &&
                          errors?.parents?.[i]?.firstName
                      )}
                      fullWidth
                      helperText={
                        touched?.parents?.[i]?.firstName &&
                        errors?.parents?.[i]?.firstName
                      }
                      label="First name"
                      name={`parents.${i}.firstName`}
                      // //onBlur={handleBlur}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values?.parents?.[i]?.firstName}
                      variant="outlined"
                      disabled={i === 0 && !caregiverInfo?.parents[0]?.id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id={`parent-${i}-last-name`}
                      error={Boolean(
                        touched?.parents?.[i]?.lastName &&
                          errors?.parents?.[i]?.lastName
                      )}
                      fullWidth
                      helperText={
                        touched?.parents?.[i]?.lastName &&
                        errors?.parents?.[i]?.lastName
                      }
                      label="Last name"
                      name={`parents.${i}.lastName`}
                      // //onBlur={handleBlur}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values?.parents?.[i]?.lastName}
                      variant="outlined"
                      disabled={i === 0 && !caregiverInfo?.parents[0]?.id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id={`parent-${i}-email`}
                      error={Boolean(
                        touched?.parents?.[i]?.email &&
                          errors?.parents?.[i]?.email
                      )}
                      fullWidth
                      helperText={
                        touched?.parents?.[i]?.email &&
                        errors?.parents?.[i]?.email
                      }
                      label="Email"
                      name={`parents.${i}.email`}
                      //onBlur={handleBlur}
                      onChange={handleChange}
                      // onBlur={async (e) => {
                      //   if (
                      //     values?.parents?.[i]?.email.length &&
                      //     values?.parents?.[i]?.email !==
                      //       caregiverInfo?.parents?.[i]?.email
                      //   ) {
                      //     const debounceResponse =
                      //       await debouncedHandleCheckEmailExistence(
                      //         values?.parents?.[0]?.email
                      //       );
                      //     if (!debounceResponse) {
                      //       toast.error(
                      //         t("common:common.Email already in Use!"),
                      //         {
                      //           id: "email-unique",
                      //         }
                      //       );
                      //     }
                      //   }
                      //   handleBlur(e);
                      // }}
                      required
                      value={values?.parents?.[i]?.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneTextInput
                      name={`parents.${i}.phoneNumber`}
                      error={Boolean(
                        touched?.parents?.[i]?.phoneNumber &&
                          errors?.parents?.[i]?.phoneNumber
                      )}
                      helperText={
                        touched?.parents?.[i]?.phoneNumber &&
                        errors?.parents?.[i]?.phoneNumber
                      }
                      value={values.parents?.[i]?.phoneNumber}
                      onChange={(phone) =>
                        setFieldValue(`parents.${i}.phoneNumber`, phone)
                      }
                      required
                      onBlur={handleBlur}
                      defaultCountry={
                        locationList?.find(
                          (obj) =>
                            obj.id ==
                            householdAndAgencyInfo?.casemanagerId?.FSCountryId
                        )?.iso2Code
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id={`parent-${i}-occupation`}
                      error={Boolean(
                        touched?.parents?.[i]?.occupation &&
                          errors?.parents?.[i]?.occupation
                      )}
                      fullWidth
                      helperText={
                        touched?.parents?.[i]?.occupation &&
                        errors?.parents?.[i]?.occupation
                      }
                      label="Occupation"
                      name={`parents.${i}.occupation`}
                      //onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.parents?.[i]?.occupation}
                      variant="outlined"
                    />
                  </Grid>
                  {values.parents.length === 1 && (
                    <Grid item xs={12} sm={6}>
                      <Button
                        sx={{
                          borderRadius: "4px",
                          width: 1,
                          height: "56px",
                        }}
                        variant="outlined"
                        onClick={() =>
                          push({
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            occupation: "",
                          })
                        }
                      >
                        + Add caregiver
                      </Button>
                    </Grid>
                  )}
                </>
              ))}
            </>
          )}
        </FieldArray>

        <Grid item xs={12}>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Button
              sx={{ borderRadius: "4px" }}
              variant="outlined"
              onClick={() => {
                setCaregiverInfo(values);
                setIsCareGiverInfo(false);
              }}
            >
              <Box display="flex">
                <ArrowLeftIcon />
                <Box>Previous</Box>
              </Box>
            </Button>
            <Box display="flex" gap={2}>
              <Button
                sx={{ borderRadius: "4px" }}
                variant="outlined"
                onClick={() => {
                  caregiverInfo?.parents[0]?.id
                    ? navigate(-1)
                    : cancelClickHandler();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ borderRadius: "4px" }}
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {caregiverInfo?.parents[0]?.id
                  ? "Update"
                  : "Add and invite family"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </FormikProvider>
    </>
  );
};

export default CareGiverForm;
