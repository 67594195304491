import { isObject } from "lodash";
import { Buffer } from "buffer";
import moment from "moment";
import { ModalService } from "../components/Modal";
import { DateFormatFromRegion } from "../constants";

export const getLocationNames = (locationList, countryID, stateID) => {
  const selectedCountry = locationList?.find(
    (individualCountry) => individualCountry.id == countryID
  );

  if (selectedCountry) {
    const countryName = selectedCountry.countryName;
    if (stateID !== null) {
      const selectedState = selectedCountry.states?.find(
        (state) => state.id === stateID
      );

      if (selectedState) {
        const stateName = selectedState.stateName;
        return stateName;
      }
    }

    return countryName;
  }
  return;
};

export const getStateList = (locationList, countryID) => {
  const selectedCountry = locationList?.find(
    (individualCountry) => individualCountry.id == countryID
  );
  if (selectedCountry) {
    const stateList = selectedCountry.states;
    return stateList;
  }
  return;
};

export const getDistrictList = (locationList, countryID, stateID) => {
  const selectedCountry = locationList?.find(
    (individualCountry) => individualCountry.id == countryID
  );
  const selectedState = selectedCountry?.states?.find(
    (individualState) => individualState.id == stateID
  );
  if (selectedState) {
    const districtList = selectedState.districts;
    return districtList;
  }
  return;
};

export const getSelectedCountryDetails = (locationList, countryID) => {
  return locationList?.find(
    (individualCountry) => individualCountry.id == countryID
  );
};

export const handleCloseFormsWarning = (t,dirty, navigate) => {
  if (dirty) {
    ModalService.open(() => <></>, {
      modalTitle: t("common:common.Unsaved Changes"),
      width: "30%",
      modalDescription:
      t(
        "common:common.If you leave this page, any changes you have made will be lost"
      ),
      actionButtonText: t("common:common.Leave page"),
      cancelButtonText:t("common:common.Cancel"),
      onClick: () => navigate(-1),
    });
  } else {
    navigate(-1);
  }
};

// export const fileUpload = async (selectedFile, signedURL) => {

//   const buffer = Buffer.from(selectedFile.base64, 'base64')
//   const response = await fetch(signedURL, {
//       method: 'PUT',
//       headers: {
//           'Content-Type': selectedFile.type
//       },
//       body: buffer
//   });
//   return response;
// };

export const fileUpload = async (selectedFile, signedURL) => {
  // Convert the selected file to a base64 string
  const reader = new FileReader();
  reader.readAsDataURL(selectedFile);
  console.log("base64String", reader);
  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      const base64String = reader.result.split(",")[1];
      const buffer = Buffer.from(base64String, "base64");
      console.log("base64String", base64String, buffer);

      try {
        const response = await fetch(signedURL, {
          method: "PUT",
          headers: {
            "Content-Type": selectedFile.type,
          },
          body: buffer,
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export function getTimeZone() {
  const timezone = moment.tz.guess();
  return timezone;
}

export function utcToLocalDate(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime).local();
  return localDateTime.format(DateFormatFromRegion());
}

export function utcToDateFormat(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime);
  return localDateTime.format(DateFormatFromRegion());
}

export function monthYear(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime);
  return localDateTime.format("MMMM YYYY"); // Output: April 2024
}

export function utcToLocalDateReverse(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime).local();
  return localDateTime.format("YYYY-MM-DD");
}

export function utcToLocal(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime).local();
  return localDateTime.format("DD-MM-YYYY h:mm:ss A");
}
export function utcToLocalWithoutSecond(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime).local();
  return localDateTime.format("DD-MM-YYYY h:mm A");
}

export function utcToLocalTime(utcDateTime) {
  const localDateTime = moment.utc(utcDateTime).local();
  return localDateTime.format("h:mm:ss A");
}

export const getDate = (dateToFormat = null) => {
  let yourDate;
  if (dateToFormat === null) {
    yourDate = new Date();
  } else {
    yourDate = new Date(dateToFormat);
  }
  // let yourDate = new Date(dateToFormat)
  // yourDate.toISOString().split('T')[0];
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split("T")[0];
};

export function formatDateTimeToISOString(dateToFormat, timeToFormat) {
  let yourDate, yourTime;
  if (dateToFormat === null) {
    yourDate = new Date();
    yourTime = new Date();
  } else {
    yourDate = new Date(dateToFormat);
    yourTime = new Date(timeToFormat);
  }

  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  yourTime = new Date(yourTime.getTime() - offset * 60 * 1000);

  const date = yourDate.toISOString().split("T")[0];
  const time = yourTime.toISOString().split("T")[1];

  const nd = `${date} ${time}`;
  try {
    return new Date(nd).toISOString();
  } catch (e) {}
}

export function formatDateTime(dateToFormat, timeToFormat) {
  let yourDate, yourTime;
  if (dateToFormat === null) {
    yourDate = new Date();
    yourTime = new Date();
  } else {
    yourDate = new Date(dateToFormat);
    yourTime = new Date(timeToFormat);
  }

  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  yourTime = new Date(yourTime.getTime() - offset * 60 * 1000);

  const date = yourDate.toISOString().split("T")[0];
  const time = yourTime.toISOString().split("T")[1].slice(0, 8);

  const nd = `${date} ${time}`;
  try {
    return nd;
  } catch (e) {}
}

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = date.toLocaleString("en-US", options);
  const dateFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = date
    .toLocaleString("en-US", dateFormatOptions)
    .replace(/,/g, ",");

  return `${formattedTime}, ${formattedDate}`;
};

export const currentDateAndTime = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  return `${day}_${month}_${year}-${hours}_${minutes}_${seconds}`;
};

export const generateUniqueKeyForImage = (fileName) => {
  const prefix = (
    Math.floor(Math.random() * (99999 - 1000 + 1)) + 1000
  ).toString();
  const resultString = prefix.concat(fileName);
  return resultString;
};

export const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join(".");
};
